import React, {Component, useEffect, useState} from 'react';
import axios from "axios";
import {Routes, Route, useParams, Navigate} from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import Header from "./Components/Header";
import DynamicFormContact from './Components/DynamicFormContact';

const AddContact = () => {

    const [supplierData, setSupplierData] = useState(0);
    const [redirect, setRedirect] = React.useState(false);

    let { recordId } = useParams();
    useEffect(() => {

    }, []);

    const fields = [
        { name: 'Name', label: 'Name',required: true },
        { name: 'Email', label: 'Email', type: 'text',required: true },
        { name: 'CellPhone', label: 'Cell Phone', type: 'text',required: true },
        { name: 'OfficePhone', label: 'Office Phone', type: 'text' },
    ];
    

    const getFormattedDate = (dateString) => {
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });

        }

    };


    if(redirect)
    {
        return (<Navigate to="/home"/>)
    }
    else {
        return (
            <>
                <Header/>

                <div className="banner text-center">
                    <div className="container">
                        <div className="row" id="banner">
                            <div className="col-lg-9 mx-auto">

                            </div>
                        </div>
                    </div>

                </div>


                <section className="section pb-0" style={{marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12">

                                <article className="card">
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-lg-12 mb-12" id="addRecord">
                                                <div className="container">
                                                    <h3 style={{marginBottom:'20px'}}>
                                                        Add Contact
                                                    </h3>
                                                    <p className="line"></p>
                                                </div>

                                                <DynamicFormContact fields={fields} />
                                            </div>
                                        </div>


                                    </div>
                                </article>

                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                   
                </section>
            </>
        );
    }

}

export default AddContact;
