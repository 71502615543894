import Logo from './logo.png';
import React, { Component } from "react";
import axios from "axios";
import { Navigate } from 'react-router-dom';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            loginStatus: 0,
            redirect: false
        };
    }

    handleUsernameInput = event => {
        this.setState({ username: event.target.value });
    };

    handlePasswordInput = event => {
        this.setState({ password: event.target.value });
    };

    onKeyUp = event => {
        if (event.charCode === 13) {
            this.authenticate(event);
        }
    };

    signup = (e) => {
        e.preventDefault();
        window.location.href = "/register";
    };

    authenticate = event => {
        event.preventDefault();
        const { username, password } = this.state;
        if (username !== "" && password !== "") {
            const userObj = { username, password };
            axios.post(`supauth/token`, userObj)
                .then(response => {
                    this.setState({ loginStatus: 1 });
                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('user_id', response.data.user_id);
                    const currentUrl = window.location.href;
                    const url = new URL(currentUrl);
                    const myParam = url.search.split('returnUrl=')[1];
                    if (myParam === undefined) {
                        axios.get(`suppliers/initial-setup`)
                            .then(response => {
                                const records = response.data.exist;
                                if (records === 0) {
                                    window.location.href = "/upload";
                                } else {
                                    this.setState({ redirect: true });
                                }
                            });
                    } else {
                        window.location.href = myParam;
                    }
                }).catch(error => {
                console.error('There was an error!', error);
                this.setState({ loginStatus: 2 });
            });
        }
    };

    componentDidMount() {
        const token = localStorage.getItem('token');
        if (token) {
            axios.get(`suppliers/is-logged-in`)
                .then(response => {
                    const authorized = response.data.authorized;
                    this.setState({ redirect: authorized });
                }).catch(error => {
                console.error('There was an error!', error);
            });
        }

        // Check for autofilled inputs after a delay to allow password manager to fill them
        setTimeout(() => {
            const username = document.getElementById('email').value;
            const password = document.getElementById('password').value;
            if (username && password) {
                this.setState({ username, password });
            }
        }, 500);
    }

    render() {
        const { loginStatus, redirect } = this.state;

        let authStatus;
        if (loginStatus === 1) {
            authStatus = <p style={{ color: 'green' }}>Authenticated</p>;
        } else if (loginStatus === 2) {
            authStatus = <p style={{ color: '#f00' }}>Incorrect Username/Password</p>;
        }

        if (redirect) {
            return <Navigate to="/home" />;
        } else {
            return (
                <>
                    <header className="navigation fixed-top">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg navbar-white">
                                <a className="navbar-brand order-1" href="/">
                                    <img className="img-fluid" width="100px" src={Logo} alt="Peak" />
                                </a>
                                <div className="collapse navbar-collapse text-center order-lg-2 order-3" id="navigation"></div>
                                <div className="order-2 order-lg-3 d-flex align-items-center"></div>
                            </nav>
                        </div>
                    </header>
                    <section className="section-sm" style={{ marginTop: '120px' }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 mx-auto" style={{ border: "1px solid #EBEBEB", padding: "28px 24px" }}>
                                    <div className="content mb-5">
                                        <h3 id="we-would-love-to-hear-from-you"><i className="fa-solid fa-user-shield"></i> Login</h3>
                                        <p style={{ borderBottom: '1px #EBEBEB solid' }}></p>
                                    </div>
                                    <form onSubmit={this.authenticate}>
                                        <div className="form-group">
                                            <label htmlFor="name">Email</label>
                                            <input type="email" name="email" onKeyPress={this.onKeyUp} onChange={this.handleUsernameInput} id="email" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Password</label>
                                            <input type="password" onKeyPress={this.onKeyUp} onChange={this.handlePasswordInput} name="password" id="password" className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <a href={'/forgot-password'} style={{ color: '#05aced', fontWeight: 'bold' }}>Forgot Password</a>
                                        </div>
                                        <div className="form-group">
                                            {authStatus}
                                        </div>
                                        <button type="submit" className="btn btn-success"><i className="fa-solid fa-lock"></i> Login</button>
                                        <button type="button" onClick={this.signup} style={{ float: "right" }} className="btn btn-danger">Signup</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );
        }
    }
}

export default Login;
