import React, { Component }  from 'react';
import axios from "axios";
import $ from "jquery";
import Header from "./Components/Header";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {Button, Modal, Tab, Tabs} from "react-bootstrap";
import {forEach} from "react-bootstrap/ElementChildren";

export class Home extends Component{
    state = {
        records: [],
        search: "",
        innerRecords: [],
        innerContacts: [],
        show: false,
        file: null,
        fileType: "",
        fileDataId: 0,
        showSpecs: false,
        showSidi: false,
        ssData: null,
        missingFile: false
    }

    onProcessAndDownload = () => {
        console.log('process and Download')
        axios.get(`suppliers/generate-data-file`)
            .then(response => {
                const data = response.data;
                let filename = data.filename;
                const element = document.createElement("a");
                element.href = axios.defaults.baseURL + 'suppliers/download-data-file/'+filename;
                element.download = "download.xlsx";
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            })

    }

    getSuppliers() {
        let _self = this;

        let dataObj = {
            search: _self.state.search,
            order: 'desc'
        }
        axios.put(`suppliers/get-suppliers`, dataObj)
            .then(response => {
                const records = response.data;
                _self.setState({ records });
                
                for (let i = 0; i < records.length; i++) {
                    const element = records[i];

                    const sdsLength = element.sds ? element.sds.length : 0;
                    const sidiLength = element.sidi ? element.sidi.length : 0;
                    
                    if (sdsLength === 0 || sidiLength === 0) {
                        let missingFile = true;
                        _self.setState({ missingFile });
                        break;
                    }
                }
            }).catch(error => {
            console.error('There was an error!', error);
        });
    }

    componentDidMount() {
        this.getSuppliers()
    }

    formatDate(dateString){
        if (dateString !== undefined && dateString.trim() !== '') {
            const [year, month, day] = dateString.split('-');
            const inputDate = new Date(year, month - 1, day, 0,0, 0);

            if (!isNaN(inputDate.getTime())) {
                const formattedDate = inputDate.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });
                return formattedDate;
            } else {
                return "Invalid Date";
            }
        } else {
            return "Invalid Input";
        }
    };

    formatDateTime(dateString){
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

        }
    };

    openPage(e, SupplierDataId){
        e.preventDefault();
        window.location.href = "/view/"+SupplierDataId;
    }

    openInner(e, SupplierData, index) {
        e.preventDefault();
        console.log(SupplierData);
        console.log(index);
        axios.get(`suppliers/get-supplier-inner-data/${SupplierData.SupplierDataMasterId}`)
            .then(response => {
                const itemData = response.data.itemData;
                const contacts = response.data.contacts;
                
                console.log(contacts);
                
                let innerRecords = this.state.innerRecords;
                innerRecords[index] = itemData;
                this.setState({ innerRecords })
                
                let innerContacts = this.state.innerContacts
                innerContacts[index] = contacts;
                this.setState({ innerContacts })
            })
    }

    onSearchClick(e)
    {
        this.setState({ search: e.target.value })
        this.getSuppliers()
    }

    onSearch(e)
    {
        this.setState({ search: e.target.value })
    }

    onKeyUp (e)
    {
        if (e.charCode === 13) {
            console.log('13');
            this.onSearchClick(e);
        }
    };

    handleClose()
    {
        this.setState({ show: false })
    }

    handleSSClose()
    {
        this.setState({ showSidi: false, showSpecs: false })
    }

    handleFileChange(e)
    {

        this.setState({ file: e.target.files[0] })
    }

    handleFileTypeChange(e)
    {
        this.setState({ fileType: e.target.value })

    }

    openFileUpload(e, SupplierDataId){
        e.preventDefault();
        this.setState({ fileDataId: SupplierDataId })
        this.setState({ show: true, showSidi: false, showSpecs: false })
        console.log(this.state);
        console.log(SupplierDataId);
        console.log("ssData", this.state.ssData);
    }

    handleDownload(file){
        var baseUrl = axios.defaults.baseURL;
        baseUrl += `suppliers/download/${file.fileId}`
        window.open(baseUrl);
    }
    
    handleSpecsView(e,p,type) {
        console.log(p);
        console.log(type);
        this.setState({ showSpecs: true, ssData: p })
    }

    handleSidiView(e,p,type) {
        console.log(p);
        console.log(type);
        this.setState({ showSidi: true, ssData: p })
    }

    

    async handleSubmit(e)
    {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', this.state.file);
        formData.append('recordId', this.state.fileDataId);
        formData.append('fileType', this.state.fileType);
        try {
            const response = await axios.post('suppliers/add-files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Response:', response.data);
            alert('File added Successfully!')
            this.handleClose()
            window.location.reload()
        } catch (error) {
            console.error('Error submitting data:', error);
            alert('Error submitting file!')
            this.handleClose()
        }
    }
    
    render() {
        return (
            <>
                <Header  />

                <Modal show={this.state.show} onHide={(e) => this.handleClose(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>File Upload</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Select File Type</label>
                        <select className={'form-control'} style={{display:"block"}} onChange={(e) => this.handleFileTypeChange(e)}>
                            <option value="">Select Type</option>
                            <option value="SDS">Specs</option>
                            <option value="SIDI">SIDI</option>
                        </select>
                        <div style={{marginTop:'14px'}}></div>
                        <label>Select File</label>
                        <input type="file" onChange={(e) => this.handleFileChange(e)}/>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={(e) => this.handleSubmit(e)}>
                            Upload
                        </Button>
                        
                        <Button variant="danger" onClick={(e) => this.handleClose(e)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showSidi || this.state.showSpecs} onHide={(e) => this.handleSSClose(e)}>
                    <Modal.Header closeButton>
                        
                        <Modal.Title>
                            {this.state.showSidi
                                ? 'SIDI Files'
                                : this.state.showSpecs
                                    ? 'Specs Files'
                                    : ''}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table style={{width: "100%", borderCollapse: "collapse", border: "1px solid #dee2e6"}}>
                            <thead>
                            <tr>
                                <th style={{border: "1px solid #dee2e6", padding: "8px"}}>Filename</th>
                                <th style={{border: "1px solid #dee2e6", padding: "8px"}}>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.showSidi && this.state.ssData?.sidi?.length > 0 ? (
                                this.state.ssData.sidi.map((file, index) => (
                                    <tr key={index}>
                                        <td style={{ border: "1px solid #dee2e6", padding: "8px" }}>
                                            {file.actualname}
                                        </td>
                                        <td style={{ border: "1px solid #dee2e6", padding: "8px" }}>
                                            <i
                                                className="ti-download pointer"
                                                onClick={() => this.handleDownload(file)}
                                                style={{ cursor: "pointer" }}
                                            ></i>
                                        </td>
                                    </tr>
                                ))
                            ) : this.state.showSpecs && this.state.ssData?.sds?.length > 0 ? (
                                this.state.ssData.sds.map((file, index) => (
                                    <tr key={index}>
                                        <td style={{ border: "1px solid #dee2e6", padding: "8px" }}>
                                            {file.actualname}
                                        </td>
                                        <td style={{ border: "1px solid #dee2e6", padding: "8px" }}>
                                            <i
                                                className="ti-download pointer"
                                                onClick={() => this.handleDownload(file)}
                                                style={{ cursor: "pointer" }}
                                            ></i>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan="2"
                                        style={{ border: "1px solid #dee2e6", padding: "8px", textAlign: "center" }}
                                    >
                                        No files found
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" style={{'position':'absolute', 'left': '32px'}} onClick={(e) => this.openFileUpload(e, this.state.ssData.data.SupplierDataId)}>
                            <i className="ti-upload pointer" title={'Upload'}></i> Upload
                        </Button>
                        <Button variant="danger" onClick={(e) => this.handleSSClose(e)}>
                        Close
                        </Button>
                    </Modal.Footer>
                </Modal>


                <section className="section pb-0" style={{marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12 row" style={{marginTop: '10px'}}>
                                <div className="input-group col-lg-7 mb-3">

                                </div>
                                <div className="input-group col-lg-5 mb-3">
                                    <input onKeyPress={(e) => this.onKeyUp(e)} type="text" className="form-control"
                                           onChange={(e) => this.onSearch(e)} placeholder="Search" aria-label="search"
                                           style={{padding: '20px'}} aria-describedby="basic-addon2"/>
                                    <div className="input-group-append">
                                    <span onClick={(e) => this.onSearchClick(e)} className="input-group-text ti-search" id="basic-addon2"></span>
                                    </div>
                                </div>
                            </div>


                            {this.state.missingFile && (
                                <div className="col-lg-12 mb-12">
                                    <p className={'ti-alert'} style={{color:'red'}}> Please fix the areas marked in red. We need additional information to correctly utilize your products.</p> 
                                </div>
                            )}


                            <div className="col-lg-12 mb-12">



                                <article className="card">


                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12 row" style={{marginBottom:'10px'}}>
                                                <div className="col-lg-6" style={{fontSize:"20px", fontWeight:"bold"}}>
                                                        Current Quote
                                                </div>
                                                <div className="col-lg-6">
                                                    <button className='btn btn-success' onClick={this.onProcessAndDownload} style={{float:"right", marginRight:"-31px"}}>
                                                        <i className='ti-download'></i> Download
                                                    </button>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        <table className="table table-bordered">
                                            <thead className="tableHead">
                                            <tr>
                                                <th scope="col">View</th>
                                                <th scope="col">Supplier Name</th>
                                                <th scope="col">Supplier Item #</th>
                                                <th scope="col">Item Description</th>
                                                <th scope="col">MOQ</th>
                                                <th scope="col">Pack Size</th>
                                                <th scope="col">U/M</th>
                                                <th scope="col"> Tier Qty 1 </th>
                                                <th scope="col">$ Cost </th>
                                                <th scope="col">Good Thru Date </th>
                                                <th scope="col">Created </th>
                                                <th scope="col">Specs </th>
                                                <th scope="col">SIDI </th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            {this.state.records.map((p, index )=> (
                                                <React.Fragment key={index}>
                                                    <tr>
                                                        <td>
                                                            <i className="ti-pencil-alt pointer" title={'Edit'} onClick={(e) => this.openPage(e, p.data.SupplierDataId)}></i>
                                                            <i className="ti-upload pointer" title={'Upload'} style={{'marginLeft':'9px'}} onClick={(e) => this.openFileUpload(e, p.data.SupplierDataId)}></i>
                                                            <i className="ti-arrow-circle-right clickable" title={'expand'} data-toggle={`collapse`} data-target={`#row${index}`} style={{cursor:'pointer', 'marginLeft':'9px'}}></i>
                                                        </td>
                                                        <td onClick={(e) => this.openInner(e, p.data, index)}>{p.data.SupplierName}</td>
                                                        <td onClick={(e) => this.openInner(e, p.data, index)}>{p.data.SupplierItem}</td>
                                                        <td onClick={(e) => this.openInner(e, p.data, index)}>{p.data.ItemDescription}</td>
                                                        <td onClick={(e) => this.openInner(e, p.data, index)}>{p.data.MOQ}</td>
                                                        <td onClick={(e) => this.openInner(e, p.data, index)}>{p.data.PackSize}</td>
                                                        <td onClick={(e) => this.openInner(e, p.data, index)}>{p.data.UM}</td>
                                                        <td onClick={(e) => this.openInner(e, p.data, index)}>{p.data.TierQty1}</td>
                                                        <td onClick={(e) => this.openInner(e, p.data, index)}>{p.data.TierQty1Cost}</td>
                                                        <td onClick={(e) => this.openInner(e, p.data, index)}>{this.formatDate(p.data.GoodThruDate)}</td>
                                                        <td onClick={(e) => this.openInner(e, p.data, index)}>{this.formatDateTime(p.data.dateTimeCreated)}</td>
                                                        <td>
                                                            {Array.isArray(p.sds) && p.sds.length > 0 ? (
                                                                <i className="ti-files pointer" onClick={(e) => this.handleSpecsView(e, p, 0)}></i>
                                                            ) : (
                                                                <i className={'ti-alert'} style={{color:'red'}}></i>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {Array.isArray(p.sidi) && p.sidi.length > 0 ? (
                                                                <i className="ti-files pointer"  onClick={(e) => this.handleSidiView(e, p, 1)}></i>
                                                            ) : (
                                                                <i className={'ti-alert'} style={{color: 'red'}}></i>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    <tr id={`row${index}`} className="collapse" >
                                                        <td colSpan="11">
                                                            
                                                            <table className="table table-bordered">
                                                                <thead className="subTableHead">
                                                                <tr>
                                                                    <th scope="col">View</th>
                                                                    <th scope="col">Supplier Name</th>
                                                                    <th scope="col">Supplier Item #</th>
                                                                    <th scope="col">Item Description</th>
                                                                    <th scope="col">MOQ</th>
                                                                    <th scope="col">Pack Size</th>
                                                                    <th scope="col">U/M</th>
                                                                    <th scope="col"> Tier Qty 1 </th>
                                                                    <th scope="col">$ Cost </th>
                                                                    <th scope="col">Good Thru Date </th>
                                                                    <th scope="col">Created </th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {this.state.innerRecords[index] && this.state.innerRecords[index].map((xx, xxindex )=> (
                                                                    <tr>
                                                                        <td>
                                                                            <i className="ti-pencil-alt" onClick={(e) => this.openPage(e, xx.SupplierDataId)}></i>
                                                                        </td>
                                                                        <td>{xx.SupplierName}</td>
                                                                        <td>{xx.SupplierItem}</td>
                                                                        <td>{xx.ItemDescription}</td>
                                                                        <td>{xx.MOQ}</td>
                                                                        <td>{xx.PackSize}</td>
                                                                        <td>{xx.UM}</td>
                                                                        <td>{xx.TierQty1}</td>
                                                                        <td>{xx.TierQty1Cost}</td>
                                                                        <td>{this.formatDate(xx.GoodThruDate)}</td>
                                                                        <td>{this.formatDateTime(xx.dateTimeCreated)}</td>
                                                                    </tr>
                                                                ))}
                                                                </tbody>
                                                            </table>

                                                        </td>
                                                    </tr>
                                                </React.Fragment>) )}

                                            {
                                                this.state.records.length === 0 ? (<tr><td colSpan="13" style={{textAlign:'center'}}>No Records</td></tr>) : (<tr></tr>)
                                            }


                                            </tbody>
                                        </table>


                                    </div>
                                </article>
                            </div>



                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Home;
