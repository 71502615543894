import React, {Component, useEffect, useState, useRef} from 'react';
import axios from "axios";
import {Routes, Route, useParams, Navigate} from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import Header from "./Components/Header";
import $ from 'jquery';
import {tr} from "date-fns/locale";
import DatePicker from "react-datepicker";

const ViewRecord = () => {

    const [supplierData, setSupplierData] = useState(0);
    const [redirect, setRedirect] = React.useState(false);
    const [editGoodThru, setEditGoodThru] = React.useState(false);
    const [GoodThru, setGoodThru] = React.useState();
    const today = new Date();
    const scrollToElement  = (currentDate) => {
        window.scrollTo(0, document.body.scrollHeight);
        setEditGoodThru(true);
        console.log(currentDate)
        setGoodThru(new Date(currentDate + "T00:00:00"));
        
    };

    let { recordId } = useParams();
    useEffect(() => {
        axios.get(`suppliers/get-supplier-data/${recordId}`)
            .then(response => {
                const supplier = response.data.supplier;
                const unauthorized = response.data.unauthorized;
                if(unauthorized === true)
                {
                    setRedirect(unauthorized)
                }
                setSupplierData(supplier)
                console.log(supplier);
            })
    }, []);

    const getFormattedDate = (dateString) => {
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString + "T00:00:00");
            
            return  myDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            
        }

    };

    const formatDateTime = (dateString) => {
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
            });

        }
    };

    const editRecord = () => {
        window.location.href = `/edit-record/${recordId}`
    };
    
    const updateRecord = () => {
        supplierData['GoodThruDate'] = GoodThru;
        console.log('ss', GoodThru);
        axios.post(`suppliers/add-supplier`, supplierData)
            .then(response => {
                alert('Record Added Successfully!');
                setTimeout(() => {
                    const month = (GoodThru.getMonth() + 1).toString().padStart(2, '0');
                    const day = GoodThru.getDate().toString().padStart(2, '0');
                    const year = GoodThru.getFullYear();
                    supplierData['GoodThruDate'] = `${year}-${month}-${day}`;
                    console.log('yy', supplierData['GoodThruDate']);
                    setEditGoodThru(false);
                }, 2000);

            }).catch(error => {
            console.error('There was an error!', error);

        });
    };

    const handleDateChange = (date) => {
        console.log(date)
        setGoodThru(date)
    };


    if(redirect)
    {
        return (<Navigate to="/home"/>)
    }
    else {
        return (
            <>
                <Header/>

                <div className="banner text-center">
                    <div className="container">
                        <div className="row" id="banner">
                            <div className="col-lg-9 mx-auto">
                                
                            </div>
                        </div>
                    </div>

                </div>


                <section className="section pb-0" style={{marginBottom: '130px'}} id={'ViewRecId'}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12">
                                <h2 className="h5 ">
                                    <a className="" href="/home">
                                        <i className="ti-angle-left"></i> Back
                                    </a>

                                </h2>
                                <article className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            
                                            <div className="col-lg-12 mb-12">
                                                <div className="widget widget-categories">
                                                    <div className="row">
                                                        <div className="col-lg-12 mb-12 row">
                                                            <div className="col-lg-6 mb-12"></div>
                                                            <div className="col-lg-6 mb-12">
                                                                <button type="button" onClick={editRecord} className="btn btn-primary"><i className="fa-solid fa-file-pen"></i> Revise</button>
                                                                <button type="button" onClick={() => scrollToElement(supplierData.GoodThruDate)} className="btn btn-primary" style={{marginLeft: '10px'}}><i className="fa-solid fa-calendar-day"></i> Update Good Thru Date </button>
                                                            </div>
                                                        </div>
                                                            <div className="col-lg-12 mb-12">
                                                                <ul className="list-unstyled widget-list">
                                                                    <li>
                                                                        <span className="d-flex">
                                                                            <span
                                                                                className="suphead">Supplier Name</span>
                                                                            <span className="supseperator">:</span>
                                                                            <span
                                                                                className="supbody">{supplierData.SupplierName}</span>
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Supplier Item #  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.SupplierItem}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Item Description  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.ItemDescription}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">MOQ  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.MOQ}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Pack Size  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.PackSize}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">U/M  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.UM}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Tier Qty 1 </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.TierQty1}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">$ Cost  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.TierQty1Cost}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Tier Qty 2  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.TierQty2}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">$ Cost  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.TierQty2Cost}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Tier Qty 3  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.TierQty3}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">$ Cost  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.TierQty3Cost}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Lead Time (days)  </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.LeadTime}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Item Category </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.ItemCategory}</span></span>
                                                                    </li>
                                                                    <li id={'GoodThruDate'}>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Good Thru Date </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody"
                                                                            style={{width: '344px'}}>
                                                                            {editGoodThru ? (
                                                                                <React.Fragment>
                                                                                    <DatePicker
                                                                                        className="form-control"
                                                                                        name='GoodThruDate'
                                                                                        selected={GoodThru}
                                                                                        minDate={today}
                                                                                        onChange={(date) => handleDateChange(date)}
                                                                                        dateFormat="MM-dd-yyyy"
                                                                                        required={true}
                                                                                    />
                                                                                    <button type="button"
                                                                                            onClick={updateRecord}
                                                                                            style={{
                                                                                                marginLeft: '10px',
                                                                                                float: 'right'
                                                                                            }}
                                                                                            className="btn btn-primary">
                                                                                        <i className="fa-solid fa-floppy-disk"></i> Update
                                                                                    </button>
                                                                                </React.Fragment>
                                                                            ) : getFormattedDate(supplierData.GoodThruDate)}
                                                                        </span>
                                                                        </span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Manufacturer </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.Manufacturer}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">FOB Origin </span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{supplierData.FOBOrigin}</span></span>
                                                                    </li>
                                                                    <li>
                                                                        <span className="d-flex"> <span
                                                                            className="suphead">Created</span><span
                                                                            className="supseperator">:</span><span
                                                                            className="supbody">{formatDateTime(supplierData.dateTimeCreated)}</span></span>
                                                                    </li>
                                                                </ul>
                                                            </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </article>

                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

}

export default ViewRecord;
