import Logo from './logo.png';
import React, { Component } from "react";
import axios from "axios";
import { Navigate } from 'react-router-dom';
import ChxBox from './Components/ChxBox';
import PhoneNumberInput from "./Components/PhoneNumberInput";
import {tr} from "date-fns/locale";

export class Signup extends Component{

    constructor(props) {
        super(props);
    };

    state = {
        username: '',
        password: '',
        firstname: '',
        lastname: '',
        phone: '',
        company: '',
        paymentTerms: '',
        creditLine:'',
        fobOrigin:'',
        fobDest:'',
        shippingAddress:'',
        registerStatus: 0,
        redirect: false,
        terms:false,
        formError:false
    }

    handleUsernameInput = event => {
        console.log(event.target.value)
        this.setState({ username: event.target.value });
    };

    handlePasswordInput = event => {
        console.log(event.target.value)
        this.setState({ password: event.target.value });
    };

    handleFirstnameInput = event => {
        console.log(event.target.value)
        this.setState({ firstname: event.target.value });
    };

    handleLastnameInput = event => {
        console.log(event.target.value)
        this.setState({ lastname: event.target.value });
    };

    handleCompanyInput = event => {
        console.log(event.target.value)
        this.setState({ company: event.target.value });
    };

    handlePaymentTermsInput = event => {
        console.log(event.target.value)
        this.setState({ paymentTerms: event.target.value });
    };
    handleCreditLineInput = event => {
        console.log(event.target.value)
        this.setState({ creditLine: event.target.value });
    };
    handleFobOriginInput = event => {
        console.log(event.target.value)
        this.setState({ fobOrigin: event.target.value });
    };
    handleFobDestInput = event => {
        console.log(event.target.value)
        this.setState({ fobDest: event.target.value });
    };
    handleShippingAddressInput = event => {
        console.log(event.target.value)
        this.setState({ shippingAddress: event.target.value });
    };

    handlePhoneInput = event => {
        console.log(event)
        const phone = event;
        this.setState({ phone });
    };

    handleCheckbox  = event => {
        console.log(event)
        this.setState({ terms: event });
    };

    openModel  = event => {
        console.log(event)
        let baseUrl = window.location.origin;
        baseUrl += "/terms-and-conditions"
        window.open(baseUrl, "_blank")
    };

    onKeyUp  = event => {
        if (event.charCode === 13) {
            this.authenticate(event);
        }
    };

    authenticate = event => {
        let userObj = {
            username:  this.state.username,
            password: this.state.password,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            phone: this.state.phone,
            company: this.state.company,
            paymentTerms: this.state.paymentTerms,
            creditLine: this.state.creditLine,
            fobOrigin: this.state.fobOrigin,
            fobDest: this.state.fobDest,
            shippingAddress: this.state.shippingAddress,
        }
        if(this.state.username !== "" &&
            this.state.password !== "" &&
            this.state.firstname !== "" &&
            this.state.lastname !== "" &&
            this.state.phone !== "" &&
            this.state.company !== "" &&
            this.state.paymentTerms !== "" &&
            this.state.creditLine !== "" &&
            this.state.fobOrigin !== "" &&
            this.state.fobDest !== "" &&
            this.state.shippingAddress !== "")
        {
            this.setState({ formError: false });
            axios.post(`supauth/`, userObj)
                .then(response => {
                    if(response.data.userCreated === 200) {
                        this.setState({ registerStatus: 1 });
                        localStorage.setItem('token', response.data.access_token)
                        localStorage.setItem('user_id', response.data.user_id)
                        alert('Account Created Successfully!');
                        setTimeout(() => {
                            this.setState({ redirect: true })
                        }, 2000);
                    } else if(response.data.userCreated === 409) {
                        alert('User/Account Already Exists!');
                    } else {
                        alert('Something went wrong, try again later');
                    }

                }).catch(error => {
                console.error('There was an error!', error);
                this.setState({ registerStatus: 2 });
            });
        }
        else
        {
            this.setState({ formError: true });
        }

    };
    componentDidMount() {

    }

    render() {

        if(this.state.redirect)
        {
            return (<Navigate to="/upload"/>)
        }
        else
        {
            return (
                <>

                    <header className="navigation fixed-top">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg navbar-white">
                                <a className="navbar-brand order-1" href="/">
                                    <img className="img-fluid" width="100px" src={Logo} alt="Peak"/>
                                </a>
                                <div className="collapse navbar-collapse text-center order-lg-2 order-3" id="navigation">

                                </div>

                                <div className="order-2 order-lg-3 d-flex align-items-center">

                                </div>

                            </nav>
                        </div>
                    </header>


                    <section className="section-sm" style={{marginTop:'120px'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 mx-auto">

                                    <div className="content mb-5">
                                        <h3 id="we-would-love-to-hear-from-you"><i className="fa-solid fa-address-card"></i> Sign Up</h3>
                                        <p style={{borderBottom:'2px #05aced solid'}}></p>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">First Name *</label>
                                        <input type="text" name="firstname" onKeyPress={this.onKeyUp} onChange={this.handleFirstnameInput} id="firstname" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Last Name *</label>
                                        <input type="text" name="lastname" onKeyPress={this.onKeyUp} onChange={this.handleLastnameInput} id="lastname" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Company  *</label>
                                        <input type="text" name="company" onKeyPress={this.onKeyUp} onChange={this.handleCompanyInput} id="company" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="phone">Cell Phone *</label>
                                        <PhoneNumberInput onPhoneNumberChange={this.handlePhoneInput} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Email *</label>
                                        <input type="email" name="email" onKeyPress={this.onKeyUp} onChange={this.handleUsernameInput} id="email" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Password *</label>
                                        <input type="password" onKeyPress={this.onKeyUp} onChange={this.handlePasswordInput}  name="password" id="password" className="form-control"  />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="name">Payment Terms *</label>
                                        <input type="text" name="payment-terms" onKeyPress={this.onKeyUp} onChange={this.handlePaymentTermsInput} id="payment-terms" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">$ Credit Line</label>
                                        <input type="text" name="credit-line" onKeyPress={this.onKeyUp} onChange={this.handleCreditLineInput} id="credit-line" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">FOB Origin</label>
                                        <input type="text" name="fob-origin" onKeyPress={this.onKeyUp} onChange={this.handleFobOriginInput} id="fob-origin" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">FOB Destination</label>
                                        <input type="text" name="fob-destination" onKeyPress={this.onKeyUp} onChange={this.handleFobDestInput} id="fob-destination" className="form-control" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="name">Shipping Address</label>
                                        <input type="text" name="shipping-address" onKeyPress={this.onKeyUp} onChange={this.handleShippingAddressInput} id="shipping-address" className="form-control" />
                                    </div>


                                    <div className="form-group">
                                        {this.state.formError === true ? (
                                            <p style={{color:"#f00"}}>All fields are required.</p>
                                        ) : ''}
                                    </div>
                                    
                                    <button type="button" onClick={this.authenticate} className="btn btn-primary"><i className="fa-solid fa-floppy-disk"></i> Signup</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </>
            );

        }
    }
}

export default Signup;
