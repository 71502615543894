import React, {Component, useEffect, useState} from 'react';
import axios from "axios";
import {Routes, Route, useParams, Navigate} from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import Header from "./Components/Header";
import DynamicFormComponent from './Components/DynamicFormComponent';

const AddRecord = () => {

    const [supplierData, setSupplierData] = useState(0);
    const [redirect, setRedirect] = React.useState(false);

    let { recordId } = useParams();
    useEffect(() => {

    }, []);

    const fields = [
        { name: 'SupplierName', label: 'Supplier Name',required: true },
        { name: 'SupplierItem', label: 'Supplier Item #', type: 'text',required: true },
        { name: 'ItemDescription', label: 'Item Description', type: 'text',required: true },
        { name: 'MOQ', label: 'MOQ', type: 'text',required: true },
        { name: 'PackSize', label: 'Pack Size', type: 'text',required: true },
        { name: 'UM', label: 'U/M', type: 'text',required: true },
        { name: 'TierQty1', label: 'Tier Qty 1', type: 'text',required: true },
        { name: 'TierQty1Cost', label: 'Cost', type: 'text',required: true, icon: 'fa-solid fa-dollar-sign' },
        { name: 'TierQty2', label: 'Tier Qty 2', type: 'text' },
        { name: 'TierQty2Cost', label: 'Cost', type: 'text', icon: 'fa-solid fa-dollar-sign' },
        { name: 'TierQty3', label: 'Tier Qty 3', type: 'text' },
        { name: 'TierQty3Cost', label: 'Cost', type: 'text', icon: 'fa-solid fa-dollar-sign' },
        { name: 'LeadTime', label: 'Lead Time', type: 'text',required: true },
        { name: 'PaymentTerms', label: 'Payment Terms', type: 'text',required: true },
        { name: 'CreditLine', label: 'Credit Line', type: 'text',required: true },
        { name: 'ItemCategory', label: 'Item Category', type: 'text',required: true },
        { name: 'GoodThruDate', label: 'Good Thru Date', type: 'date',required: true },
        { name: 'FOBOrigin', label: 'FOB Origin', type: 'text',required: true},
        { name: 'FOBDestination', label: 'FOB Destination', type: 'text',required: true},
        { name: 'ShippingAddress', label: 'Shipping Address', type: 'text',required: true },
    ];

    const getFormattedDate = (dateString) => {
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });

        }

    };


    if(redirect)
    {
        return (<Navigate to="/home"/>)
    }
    else {
        return (
            <>
                <Header/>

                <div className="banner text-center">
                    <div className="container">
                        <div className="row" id="banner">
                            <div className="col-lg-9 mx-auto">

                            </div>
                        </div>
                    </div>

                </div>


                <section className="section pb-0" style={{marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12">
      
                                <article className="card">
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-lg-12 mb-12" id="addRecord">
                                                <div className="container">
                                                    <h3 style={{marginBottom:'20px'}}>
                                                        Add Data
                                                    </h3>
                                                    <p className="line"></p>
                                                </div>

                                                <DynamicFormComponent fields={fields} />
                                            </div>
                                        </div>


                                    </div>
                                </article>

                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }

}

export default AddRecord;
