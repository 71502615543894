import React, {Component, useEffect, useState} from 'react';
import axios from "axios";
import {Routes, Route, useParams, Navigate} from 'react-router-dom';
import "yet-another-react-lightbox/styles.css";
import Header from "./Components/Header";
import DynamicFormComponent from './Components/DynamicFormComponent';
import {tr} from "date-fns/locale";

const EditRecord = () => {

    const [supplierData, setSupplierData] = useState(0);
    const [fieldData, setFieldData] = useState([]);
    const [redirect, setRedirect] = React.useState(false);
    const [editMode, setEditMode] = React.useState(true);

    let { Id } = useParams();
    useEffect(() => {
        axios.get(`suppliers/get-supplier-data/${Id}`)
            .then(response => {
                const supplier = response.data.supplier;
                const unauthorized = response.data.unauthorized;
                if(unauthorized === true)
                {
                    setRedirect(unauthorized)
                }
                setSupplierData(supplier)
                console.log(supplier);
                console.log(supplier['MOQ']);
                const fields = [
                    { name: 'SupplierName', label: 'Supplier Name',required: false, hidden:true, defaultValue: supplier['SupplierName']  },
                    { name: 'SupplierItem', label: 'Supplier Item #', type: 'text',required: false, hidden:true, defaultValue: supplier['SupplierItem'] },
                    { name: 'ItemDescription', label: 'Item Description', type: 'text',required: false, hidden:true, defaultValue: supplier['ItemDescription'] },
                    { name: 'MOQ', label: 'MOQ', type: 'text',required: true, defaultValue: supplier['MOQ'] },
                    { name: 'PackSize', label: 'Pack Size', type: 'text',required: true,  defaultValue: supplier['PackSize'] },
                    { name: 'UM', label: 'U/M', type: 'text',required: true,  defaultValue: supplier['UM']  },
                    { name: 'TierQty1', label: 'Tier Qty 1', type: 'text',required: true,  defaultValue: supplier['TierQty1']  },
                    { name: 'TierQty1Cost', label: 'Cost', type: 'text',required: true, icon: 'fa-solid fa-dollar-sign',  defaultValue: supplier['TierQty1Cost'] },
                    { name: 'TierQty2', label: 'Tier Qty 2', type: 'text',  defaultValue: supplier['TierQty2']  },
                    { name: 'TierQty2Cost', label: 'Cost', type: 'text', icon: 'fa-solid fa-dollar-sign',  defaultValue: supplier['TierQty2Cost']  },
                    { name: 'TierQty3', label: 'Tier Qty 3', type: 'text',  defaultValue: supplier['TierQty3']  },
                    { name: 'TierQty3Cost', label: 'Cost', type: 'text', icon: 'fa-solid fa-dollar-sign',  defaultValue: supplier['TierQty3Cost']  },
                    { name: 'LeadTime', label: 'Lead Time', type: 'text',required: true,  defaultValue: supplier['LeadTime']  },
                    { name: 'ItemCategory', label: 'Item Category', type: 'text',required: true,  defaultValue: supplier['ItemCategory']  },
                    { name: 'GoodThruDate', label: 'Good Thru Date', type: 'date',required: true,  defaultValue: new Date(supplier['GoodThruDate'] + "T00:00:00")  },
                ];
                setFieldData(fields)
            })
    }, []);

    

    const getFormattedDate = (dateString) => {
        if(dateString !== undefined)
        {
            const myDate = new Date(dateString);

            return  myDate.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });

        }

    };


    if(redirect)
    {
        return (<Navigate to="/home"/>)
    }
    else {
        return (
            <>
                <Header/>

                <div className="banner text-center">
                    <div className="container">
                        <div className="row" id="banner">
                            <div className="col-lg-9 mx-auto">

                            </div>
                        </div>
                    </div>

                </div>


                <section className="section pb-0" style={{marginBottom: '130px'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-12">

                                <article className="card">
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="col-lg-12 mb-12" id="addRecord">
                                                <div className="container">
                                                    <h3 style={{marginBottom:'20px'}}>
                                                        Edit Data
                                                    </h3>
                                                    <p className="line"></p>
                                                </div>
                                                {supplierData && <DynamicFormComponent fields={fieldData} editMode={editMode} />}
                                                
                                            </div>
                                        </div>


                                    </div>
                                </article>

                            </div>


                            <div className="col-12">
                                <div className="border-bottom border-default"></div>
                            </div>
                        </div>
                    </div>
   
                </section>
            </>
        );
    }

}

export default EditRecord;
